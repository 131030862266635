#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:400px;}
/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */

.mc__form-container {
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  text-align: center;
}

.mc__form-container h3 {
  line-height: 1.2;
  margin-top: 0;
}

input[type="text"], input[type="button"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input[type="email"] {
  min-width: 250px;
  padding: 8px 15px;
  border: 2px solid #444;
  border-radius: 5px;
}

input[type="email"]:focus,
input[type="email"]:focus-visible {
  border: 2px solid #c6305d;
  outline: none;
}

.inputField__label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.primaryBtn.primaryBtn--big:disabled {
  background-color: #bbb;
}
.primaryBtn.primaryBtn--big:not(:disabled) {
  cursor: pointer;
}
.primaryBtn.primaryBtn--big:not(:disabled):hover {
  background-color: #9b3251;
}

.primaryBtn.primaryBtn--big {
  margin-top: 10px;
  background-color: #c6305d;
  border: none;
  outline: none;
  color: #fff;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 14px;
  transition: all .3s cubic-bezier(.25,.01,.1,1);
}

.mc__alert.mc__alert--error {
  font-size: 14px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  color: #ff0033;
}

@media (max-width: 650px) {
  .Main.subscribe-overlay .mc__form-container {
    width: calc(100% - 50px);
    max-width: 100%;
    left: 25px;
  }
}