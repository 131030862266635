.page .header {
  width: 100%;
  float: left;
  padding-top: 150px;
  position: relative;
  text-align: center;
  z-index: 2;
  padding-bottom: 60px;
}

.page p {
  font-size: 16px;
  line-height: 1.5;
}

.page .header h1 {
  font-size: 62px;
  margin: 0;
}

.page .container {
  /* max-width: 980px; */
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

.page .header .header-content {
  margin-left: 10%;
  width: 80%;
  color: #0b2940;
}

.page .header .hero-sub {
  font-size: 28px;
  font-size: 1.75em;
  /* color: #000; */
  color: #00adee;
  margin: 1rem 0 0;
}

.page .header .hero-text {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.page .section-grid {
  width: 100%;
  float: left;
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: #f9f9f9;
}

.page .section-grid .grid-item {
  text-align: center;
  margin-top: 70px;
}

.page .section-grid .grid-item p {
  padding: 0 40px;
}

.page .section-grid .grid-item.link {
  text-decoration: none;
}
.page .section-grid .grid-item.link,
.page .section-grid .grid-item.link:visited {
  color: #000;
}
.page .section-grid .grid-item.link h3,
.page .section-grid .grid-item.link p {
  transition: all .3s cubic-bezier(.25,.01,.1,1);
}
.page .section-grid .grid-item.link:active h3,
.page .section-grid .grid-item.link:active p,
.page .section-grid .grid-item.link:hover h3,
.page .section-grid .grid-item.link:hover p {
  color: #c6305d;
}

.page .section-grid h3 {
  font-size: 28px;
  font-weight: 300;
  margin-top: 0;
}

@media (min-width: 768px) {
  .page .section-grid .grid-item {
    width: calc(33.33333% - 10px);
    float: left;
    text-align: center;
    margin-top: 0;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 1400px) {
  .page .section-grid .container {
    max-width: 1180px;
  }
}

@media (max-width: 1200px) {
  .page .section-grid .container {
    max-width: 1140px;
  }
}

@media (max-width: 650px) {
  .page .section-grid .grid-item img {
    display: block;
    margin: 0 auto;
    max-width: 100px;
    height: auto;
  }
  .page .section-grid .grid-item {
    display: block;
    margin-top: 0;
  }
  .page .section-grid .grid-item + .grid-item {
    margin-top: 50px;
  }
}