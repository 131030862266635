.nav-tabs-container {
  text-align: center;
  margin-top: -26px;
  width: 100%;
  float: left;
}

.nav-tabs {
  width: 444px;
  margin-left: auto;
  margin-right: auto;
  
  display: inline-block;
}

.nav-tabs a {
  width: 220px;
  float: left;
  font-size: 18px;
  padding: 15px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  color: #444;
  border: 2px solid #444;
  background-color: #fff;
}

.nav-tabs a:first-of-type {
  border-radius: 50px 0 0 50px;
}
.nav-tabs a:last-of-type {
  border-radius: 0 50px 50px 0;
}

.nav-tabs a.active {
  background-color: #444;
  color: #fff;
}