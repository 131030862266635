@font-face {
  font-family: 'Proxima Soft';
  src: local('Proxima Soft Regular'), local('Proxima-Soft-Regular'),
      url('./fonts/ProximaSoft-Regular.woff2') format('woff2'),
      url('./fonts/ProximaSoft-Regular.woff') format('woff'),
      url('./fonts/ProximaSoft-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Soft';
  src: local('Proxima Soft Bold'), local('Proxima-Soft-Bold'),
      url('./fonts/ProximaSoft-Bold.woff2') format('woff2'),
      url('./fonts/ProximaSoft-Bold.woff') format('woff'),
      url('./fonts/ProximaSoft-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

div {
  display: block;
}

body {
  font-family: 'Proxima Soft', "Helvetica Neue", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

h3 {
  font-size: 28px;
  font-weight: 300;
}

.App {
  width: 100%;
  max-width: 1400px;
  position: relative;
  margin: 0 auto;
  overflow-x: hidden;
  box-shadow: 0 0 5px rgb(0 0 0 / 5%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

.container:after {
  content: '';
  display: table;
  clear: both;
}

.Main {
  width: 100%;
  float: left;
  padding-top: 50px;
  padding-bottom: 100px;
}

.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .row {
    flex-direction: row;
  }
}

.col {
  flex-basis: 100%;
  max-width: 100%;
}
.cols-split {
  column-count: 2;
  column-gap: 0.5vw;
}

@media only screen and (min-width: 640px) {
  .col {
    flex-basis: calc(16.6666667% - 1.6666667px);
    max-width: calc(16.6666667% - 1.6666667px);
  }
  .col-2x {
    flex-basis: 33.3333334%;
    max-width: 33.3333334%;
  }
}

.margin-t, .margin-t-1 {
  margin-top: 10px;
}
.margin-t-2, .margin-t-2 {
  margin-top: 20px;
}
.margin-t-3, .margin-t-3 {
  margin-top: 30px;
}
.margin-t-4, .margin-t-4 {
  margin-top: 40px;
}
.margin-t-5, .margin-t-5 {
  margin-top: 50px;
}

table,
th,
td {
  border: none;
  border-collapse: collapse;
}

table {
  width: 100%;
  font-size: 14px;
}

th,
td {
  padding: 5px 10px;
}

tr {
  text-align: left;
  vertical-align: left;
}

thead tr th:first-of-type {
  padding-left: 33px;
}

thead th {
  vertical-align: top;
  position: relative;
}

thead th .sort {
  position: absolute;
  left: 14px;
  cursor: pointer;
}

thead th .sort svg {
  fill: #aaa;
}
thead th .sort:hover svg,
thead th .sort.active svg {
  fill: #000;
}

thead th svg {
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
  fill: #aaa;
}

.table-wrapper {
  box-shadow: 0px -1px 8px -1px rgb(0 0 0 / 20%);
}
.table-wrapper-inner {
  padding: 1.5vw;
  position: relative;
  clear: both;
  background-color: #f9f9f9;
}

.table-wrapper table {
  margin: 0 auto;
  clear: both;
  border-spacing: 0;
}

.table-wrapper thead th {
  background-color: #f9f9f9;
  color: #000;
  border-bottom: #e7e7e7 1px solid;
  font-size: inherit;
  font-weight: normal;
  text-transform: none;
  width: auto !important;
  vertical-align: middle;
  font-weight: bold;
  color: #0b2940;
}

.table-wrapper thead th:not(.drug) {
  text-align: center;
}

.table-wrapper thead th.sortable {
  cursor: pointer;
}

.table-wrapper tbody td {
  padding: 1.25vw 1vw;
}
.table-wrapper tbody .tr-expandable td {
  padding-top: 0;
  padding-left: 35px;
  padding-right: 50px;
}
.table-wrapper tbody td.patentNo {
  min-width: 140px;
}
.tr-expandable strong.label.margin-l {
  margin-left: 20px;
}

.table-wrapper tbody tr:not(.tr-expandable) td:not(.drug) {
  text-align: center;
}

.table-wrapper tbody tr:hover,
.table-wrapper tbody tr:hover ~ tr.tr-expandable {
  background-color: #f4f4f4;
}

.table-wrapper td.patentNo a {
  color: #c6305d;
  transition: all .3s cubic-bezier(.25,.01,.1,1);
  cursor: pointer;
}
.table-wrapper td.patentNo a:hover {
  color: #ad1744;
  text-decoration: underline;
}

/* Filters */
.filters {
  width: 100%;
  float: left;
  font-size: 14px;
  background-color: #081f30;
  margin-bottom: 20px;
  color: #fff;
}

.filters h3 {
  margin-top: 0;
}
.filters h4 {
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #88c4f5;
}

/* .filter-group-sub + .filter-group-sub {
  margin-top: 15px;
} */

.filter-group + .filter-group {
  /* border-left: 2px solid #fff; */
  border-left: 2px solid rgba(255, 255, 255, 0.2);
}

.filter-group-inner {
  max-height: 272px;
  overflow-y: scroll;
  margin-top: -1px;
  padding-top: 1px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 15px;

  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.filter-group-inner::-webkit-scrollbar {
  display: none;
}

.filter-group h4 {
  padding: 15px 10px 0 15px;
}

.fieldset {
  line-height: 18px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.fieldset + .fieldset {
  margin-top: 0.5em;
}

.fieldset--disabled {
  color: #959495;
  cursor: not-allowed;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  border: 1px solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #c6305d;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  /* outline: max(2px, 0.15em) solid currentColor; */
  /* outline-offset: max(2px, 0.15em); */
  outline: none;
}

input[type="checkbox"]:disabled {
  --form-control-color: #959495;

  color: #959495;
  cursor: not-allowed;
}

input[type="checkbox"],
label {
  cursor: pointer;
}

.tr-expandable {
  display: none;

}
.tr-expandable.expanded {
  display: table-row;
}
td.drug {
  width: 120px;
  padding-left: 15px;
  cursor: pointer;
}
td.dateFile,
td.dateExpiry {
  width: 110px;
}
td.assignee {
  width: 80px;
}
td.drug svg {
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
  fill: #aaa;
  transition: all .3s cubic-bezier(.25,.01,.1,1);
}
td.drug:hover svg,
td.drug.expanded svg {
  fill: #0b2940;
}
td.drug .drug-toggle {
  margin-right: 10px;
  cursor: pointer;
}

.filters-heading {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.filters-heading h3 {
  float: left;
  margin: 0;
  line-height: 33px;
}
.filters-heading .btn {
  float: right;
}
.filters-heading .btn-clear.hidden {
  visibility: hidden;
  cursor: none;
}
.btn {
  background-color: #c6305d;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  transition: all .3s cubic-bezier(.25,.01,.1,1);
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  border: none;
}

.btn-grey {
  color: #000;
  background-color: #dfdfdf;
}

.btn-grey:hover {
  background-color: #bebebe;
  /* color: #fff; */
}

/* clear filters */
.results-count-heading,
.table-wrapper {
  width: 100%;
  float: left;
}
.results-count-heading {
  margin-bottom: 20px;
}
.results-count-heading p {
  float: left;
}

.download-data {
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .3s cubic-bezier(.25,.01,.1,1);
  float: right;
  margin: 1em 0;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: #000;
}

.download-data:hover {
  color: #c6305d;
}
.download-data:hover svg {
  fill: #c6305d;
}

.download-data svg {
  width: 10px;
  height: 10px;
  display: inline-block;
  transition: all .3s cubic-bezier(.25,.01,.1,1);
}

#patentNoInput {
  width: calc(100% - 10px);
}


/* Date ranges */
.filter-heading.date {
  position: relative;
}


.cal-icon {
  /* position: absolute; */
  /* left: 14px; */
  cursor: pointer;
}

.cal-icon svg {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  fill: #fff;
}

.cal-icon:hover svg {
  /* fill: #c6305d; */
  fill: #88c4f5;
}

.rdrCalendarWrapper {
  position: absolute;
  z-index: 10;
  left: 15px;
  top: 35px;
  box-shadow: 0px -1px 8px -1px rgb(0 0 0 / 20%);
}

.fieldset-date {
  width: 100%;
  float: left;
  margin-bottom: 5px;
}

.fieldset-date label {
  width: 50px;
  float: left;
  line-height: 21px;
}
.fieldset-date input {
  width: calc(100% - 62px);
  float: left;
  outline: none;
  border: 1px solid rgba(255,255,255,0.1);
  padding: 2px 5px;
  background: transparent;
  color: #fff;
}

.clear-date {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  background-color: rgba(255,255,255,0.2);
  padding: 5px 10px;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  color: #fff;
  float: right;
  line-height: 15px;
}
.clear-date:hover {
  /* background-color: rgba(255,255,255,0.4); */
  color: #88c4f5;
}
.clear-date.hidden {
  visibility: hidden;
  cursor: none;
}

.no-results {
  text-align: center;
  padding: 2em 0;
}


/* Overlay */
.Main.subscribe-overlay {
  position: relative;
}

.Main.subscribe-overlay:before {
  content: '';
  position: absolute;
  width: 100%;
  height: calc(100% + 50px);
  top: -50px;
  left: 0;
  background-color: rgba(8, 31, 48, 0.95);
  z-index: 5;
}

.Main.subscribe-overlay .mc__form-container {
  position: absolute;
  top: 20px;
  left: calc(50% - 230px);
  z-index: 10;
  background-color: #fff;
  padding: 40px 30px;
}