.container-scroll-x {
  overflow-x: scroll;
  min-height: 400px;
}

.pvtUi {
 color: #0b2940; 
 font-family: 'Proxima Soft', "Helvetica Neue", Helvetica Neue, Helvetica, Arial, sans-serif;
}

table.pvtTable {
  font-family: 'Proxima Soft', "Helvetica Neue", Helvetica Neue, Helvetica, Arial, sans-serif;
}

.pvtAxisContainer, .pvtVals {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #0b2940;
}

table.pvtTable thead tr th, table.pvtTable tbody tr th, table.pvtTable tbody tr td, .pvtAxisContainer li span.pvtAttr, .pvtDropdownCurrent {
  border: 1px solid #0b2940;
}

.pvtDropdownIcon,
table.pvtTable tbody tr td {
  color: #0b2940;
}

.pvtDropdownActiveValue,
.pvtCheckContainer p.selected {
  background-color: #dfdfdf;
}

.pvtFilterBox input[type='text'] {
  padding: 5px 10px;
  color: #0b2940;
  border: 1px solid #0b2940;
}

.pvtCheckContainer p.selected {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pvtDropdownValue {
  padding: 5px 10px;
  font-size: 14px;
}

.pvtSearch,
.pvtFilterBox input[type='text'] {
  width: 230px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.pvtButton {
  color: #000;
  background-color: #dfdfdf;
  border-color: #dfdfdf;
}

.pvtButton:hover {
  background-color: #bebebe;
  border-color: #bebebe;
}

.pvtAxisContainer li span.pvtAttr, .pvtDropdownCurrent {
  padding: 5px 10px;
}

.pvtAxisContainer li span.pvtAttr, .pvtDropdownCurrent {
  font-size: 14px;
  position: relative;
}

.pvtDropdownIcon {
  position: absolute;
  right: 5px;
  top: 2px;
}

.pvtDropdownCurrentOpen .pvtDropdownIcon {
  font-size: 18px;
  top: 4px;
}

table.pvtTable thead tr th, table.pvtTable tbody tr th,
table.pvtTable tbody tr td {
  padding: 8px 10px;
  font-size: 11px;
}

.pvtCloseX {
  font-size: 20px;
  font-weight: 700;
  right: 8px;
}
