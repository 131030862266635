nav.nav-primary {
  width: 100%;
  float: left;
  position: absolute;
  left: 0;
  top: 0;
  /* top: 40px; */
  z-index: 10;
  padding-top: 20px;
}

nav .nav-primary-container {
  width: 100%;
  padding: 0 25px;
}

nav .nav-logo {
  width: 200px;
  height: 70px;
  display: inline-block;
}

nav .nav-logo svg .st0 {
  fill: #2bace2;
}

nav .nav-logo svg .st1 {
  fill: #1a3760;
}